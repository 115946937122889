import React from 'react';
import classNames from 'classnames';
import { SEO } from '../../components/SEO';
import { Header } from '../../components/Header';
import { Layout } from '../../layouts/Layout';
import { Footer } from '../../components/Footer';
import { Video } from '../../components/Video';
import LogoTypoMonoSVG from '../../resources/logo_typo_mono.svg';

const contents = [
  {
    title: '매트치료',
    content: `
      보바스, PNF 등 다양한 치료기법을 이용하여 신체 움직임을 개선하고 적절한 근육의 긴장도를 유지하여 정상적인 움직임을 재학습시킵니다.
      환자에 대한 신경생리학적인 검사와 움직임의 평가를 통해 기능적 활동과 동작 그리고 균형 능력 정도를 분석합니다. 특히 운동치료를 통한 보행훈련은 중추신경계 손상과 근골격계 질환을 가진 개인의 근육 협응장애와 마비가 특징이 되는 비정상적인 보행을 최소화하고 정상적인 보행 패턴을 반복 훈련하여 기능적이고 효과적인 보행을 유지 및 개선할 수 있도록 돕습니다.
    `,
    video: '/video/J_Physics_01.mp4',
  },
  {
    title: '보행치료',
    content: `
      중추신경계 질환이나 사지가 불편한 환자에게 보행은 몸통과 사지 조절의 정확성을 요구를 위해 필요한 단계로 중추신경계 손상과 근·골격계 질환이 있는 환자에게 가능한 적은 보상작용으로 안전하고 기능적인 보행을 위하여 비정상적인 보행을 최소화하고 정상적인 보행 방향을 잡아가는 훈련을 하며 다양한 환경에서 보다 효과적인 보행을 유지, 개선하고자 하는 훈련입니다.
    `,
    video: '/video/J_Physics_02.mp4',
  },
  {
    title: '자세조절',
    content: `
      기능적인 과제를 수행하는 데 필요한 긴장도, 동작 패턴 및 자세 조절을 이해시키고 근육 활성화를 통해 과제 수행의 효율성과 성공률 증진시킵니다.
    `,
    video: '/video/J_Physics_03.mp4',
  },
  {
    title: '기능훈련',
    content: `
      치료사가 동작분석을 통해 환자에게 필요한 과제를 서로의 노력과 근거를 통해 성취해가는 과제 지향적 접근법까지 다양한 형태의 재활 운동프로그램을 통해 환자의 잠재된 기능이나 잔존기능을 최대화하기 위해 노력합니다.
    `,
    video: '/video/J_Physics_04.mp4',
  },
  {
    title: '전기치료',
    content: `
      통증 치료실에서는 온열과, 광선(적외선) 전기(저, 중, 고주파 등)를 이용하여 통증 감소, 염증 완화, 근육 경련 완화, 등의 효과를 얻어 환자의 통증을 경감시키고 정상적인 사회 활동을 하는 데 도움을 주는 역할을 합니다.
    `,
    video: '/video/J_Physics_05.mp4',
  },
  {
    title: '온열치료',
    content: `
      적외선기기, 핫팩 등 통증을 완화하거나 조직의 치유를 촉진 및 신체의 움직임을 향상하고자 사용하는 치료 기법입니다.
    `,
    video: '/video/J_Physics_06.mp4',
  },
  {
    title: '초음파치료',
    content: `
      초음파를 이용해 체내에 열을 발생시키는 치료로 초음파가 조직, 근육 속 깊이 침투하여 내부에 열을 발생시켜 혈류량 증진,연부조직의 신장성 증가, 관절 강직의 감소, 통증 및 근육 연축의 완화, 염증 복구 촉진, 골절 치유 촉진 등에 효과적입니다.
    `,
    video: '/video/J_Physics_07.mp4',
  },
  {
    title: '전동관절 운동치료',
    content: `
      전동관절운동치료(Continuous Passive Motion): 관절 수술 후에 재활치료에 적용하여 동적, 지속적인 엉덩관절 및 무릎관절 운동을 위해 CPM 기구를 이용하여 관절 운동 범위를 유지 및 회복시키는 치료기구입니다.
    `,
    video: '/video/J_Physics_08.mp4',
  },
  {
    title: '기능적 전기자극치료(FES)',
    content: `
      중추신경계로 손상으로 인해 약화, 마비된 근육에 적합한 강도의 전기 자극을 순차적으로 가하여 주어진 기능을 수행하도록 도와줍니다. 일반적으로 저주파 자극이 이용되며, 인체에 안전하고 근육의 피로를 최소화하며 지속적인 기능 수행을 가능하게 합니다. 마비나 약화된 근육에 기능적인 전기 자극을 제공하여 근육 약화 예방 및 근육 조절 능력을 향상시키는 치료 방법입니다.
    `,
    video: '/video/J_Physics_09 FES.mp4',
  },
];

const Section = ({ className = '', title, content, video }) => {
  return (
    <div
      className={classNames(
        'relative flex flex-col overflow-x-hidden items-center justify-center w-full m-auto text-white max-w-screen-sm mx-auto',
        className
      )}
    >
      <div className="relative w-full aspect-square">
        <Video src={video} className="w-full h-full" />
        <div
          className="absolute bottom-0 left-0 right-0 h-[122px] flex justify-between px-6 items-end"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(123, 116, 109, 0.85) 69%, #635b53)`,
          }}
        >
          <h1
            className="text-2xl font-extrabold text-white"
            style={{
              wordBreak: 'keep-all',
              textShadow: `0 2px 8px rgba(0, 0, 0, 0.65)`,
            }}
          >
            {title}
          </h1>
          <LogoTypoMonoSVG className="w-auto h-[26px]" />
        </div>
      </div>
      <div className="px-6 py-6 text-[16px] pb-12 min-h-[180px]" style={{ lineHeight: 1.75 }}>
        {content}
      </div>
    </div>
  );
};

export default function RehabDetailPage({ title = '물리 치료', ...props }) {
  return (
    <Layout className="relative bg-slate">
      <SEO title={title} titleTemplate="" />
      <Header showBreadcrumb noHeight backHref="/rehab" className="sticky top-0 left-0 right-0 z-10" />
      <div className="flex w-full h-[60px] items-center justify-center font-bold text-xl bg-slate text-white">
        {title}
      </div>

      {contents.map((props, idx) => (
        <Section {...props} key={idx} />
      ))}

      <Footer />
    </Layout>
  );
}
